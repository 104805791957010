import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import AkademikinfoList from 'pages/akademikinfo/List';
import AkademikinfoView from 'pages/akademikinfo/View';
import AkademikinfoAdd from 'pages/akademikinfo/Add';
import AkademikinfoEdit from 'pages/akademikinfo/Edit';
import DokumeninfoList from 'pages/dokumeninfo/List';
import DokumeninfoView from 'pages/dokumeninfo/View';
import DokumeninfoAdd from 'pages/dokumeninfo/Add';
import DokumeninfoEdit from 'pages/dokumeninfo/Edit';
import InvoicesList from 'pages/invoices/List';
import InvoicesView from 'pages/invoices/View';
import InvoicesAdd from 'pages/invoices/Add';
import InvoicesEdit from 'pages/invoices/Edit';
import KabkotaList from 'pages/kabkota/List';
import KabkotaView from 'pages/kabkota/View';
import KabkotaAdd from 'pages/kabkota/Add';
import KabkotaEdit from 'pages/kabkota/Edit';
import KecamatanList from 'pages/kecamatan/List';
import KecamatanView from 'pages/kecamatan/View';
import KecamatanAdd from 'pages/kecamatan/Add';
import KecamatanEdit from 'pages/kecamatan/Edit';
import KelurahanList from 'pages/kelurahan/List';
import KelurahanView from 'pages/kelurahan/View';
import KelurahanAdd from 'pages/kelurahan/Add';
import KelurahanEdit from 'pages/kelurahan/Edit';
import ProvinsiList from 'pages/provinsi/List';
import ProvinsiView from 'pages/provinsi/View';
import ProvinsiAdd from 'pages/provinsi/Add';
import ProvinsiEdit from 'pages/provinsi/Edit';
import RolepermissionsList from 'pages/rolepermissions/List';
import RolepermissionsView from 'pages/rolepermissions/View';
import RolepermissionsAdd from 'pages/rolepermissions/Add';
import RolepermissionsEdit from 'pages/rolepermissions/Edit';
import RolesList from 'pages/roles/List';
import RolesView from 'pages/roles/View';
import RolesAdd from 'pages/roles/Add';
import RolesEdit from 'pages/roles/Edit';
import SiswaList from 'pages/siswa/List';
import SiswaView from 'pages/siswa/View';
import SiswaAdd from 'pages/siswa/Add';
import SiswaEdit from 'pages/siswa/Edit';
import UniversitasList from 'pages/universitas/List';
import UniversitasView from 'pages/universitas/View';
import UniversitasAdd from 'pages/universitas/Add';
import UniversitasEdit from 'pages/universitas/Edit';
import UsersList from 'pages/users/List';
import UsersView from 'pages/users/View';
import UsersAdd from 'pages/users/Add';
import UsersEdit from 'pages/users/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* akademikinfo pages routes */}
				<Route path="/akademikinfo" element={<AkademikinfoList />} />
				<Route path="/akademikinfo/:fieldName/:fieldValue" element={<AkademikinfoList />} />
				<Route path="/akademikinfo/index/:fieldName/:fieldValue" element={<AkademikinfoList />} />
				<Route path="/akademikinfo/view/:pageid" element={<AkademikinfoView />} />
				<Route path="/akademikinfo/add" element={<AkademikinfoAdd />} />
				<Route path="/akademikinfo/edit/:pageid" element={<AkademikinfoEdit />} />

				{/* dokumeninfo pages routes */}
				<Route path="/dokumeninfo" element={<DokumeninfoList />} />
				<Route path="/dokumeninfo/:fieldName/:fieldValue" element={<DokumeninfoList />} />
				<Route path="/dokumeninfo/index/:fieldName/:fieldValue" element={<DokumeninfoList />} />
				<Route path="/dokumeninfo/view/:pageid" element={<DokumeninfoView />} />
				<Route path="/dokumeninfo/add" element={<DokumeninfoAdd />} />
				<Route path="/dokumeninfo/edit/:pageid" element={<DokumeninfoEdit />} />

				{/* invoices pages routes */}
				<Route path="/invoices" element={<InvoicesList />} />
				<Route path="/invoices/:fieldName/:fieldValue" element={<InvoicesList />} />
				<Route path="/invoices/index/:fieldName/:fieldValue" element={<InvoicesList />} />
				<Route path="/invoices/view/:pageid" element={<InvoicesView />} />
				<Route path="/invoices/add" element={<InvoicesAdd />} />
				<Route path="/invoices/edit/:pageid" element={<InvoicesEdit />} />

				{/* kabkota pages routes */}
				<Route path="/kabkota" element={<KabkotaList />} />
				<Route path="/kabkota/:fieldName/:fieldValue" element={<KabkotaList />} />
				<Route path="/kabkota/index/:fieldName/:fieldValue" element={<KabkotaList />} />
				<Route path="/kabkota/view/:pageid" element={<KabkotaView />} />
				<Route path="/kabkota/add" element={<KabkotaAdd />} />
				<Route path="/kabkota/edit/:pageid" element={<KabkotaEdit />} />

				{/* kecamatan pages routes */}
				<Route path="/kecamatan" element={<KecamatanList />} />
				<Route path="/kecamatan/:fieldName/:fieldValue" element={<KecamatanList />} />
				<Route path="/kecamatan/index/:fieldName/:fieldValue" element={<KecamatanList />} />
				<Route path="/kecamatan/view/:pageid" element={<KecamatanView />} />
				<Route path="/kecamatan/add" element={<KecamatanAdd />} />
				<Route path="/kecamatan/edit/:pageid" element={<KecamatanEdit />} />

				{/* kelurahan pages routes */}
				<Route path="/kelurahan" element={<KelurahanList />} />
				<Route path="/kelurahan/:fieldName/:fieldValue" element={<KelurahanList />} />
				<Route path="/kelurahan/index/:fieldName/:fieldValue" element={<KelurahanList />} />
				<Route path="/kelurahan/view/:pageid" element={<KelurahanView />} />
				<Route path="/kelurahan/add" element={<KelurahanAdd />} />
				<Route path="/kelurahan/edit/:pageid" element={<KelurahanEdit />} />

				{/* provinsi pages routes */}
				<Route path="/provinsi" element={<ProvinsiList />} />
				<Route path="/provinsi/:fieldName/:fieldValue" element={<ProvinsiList />} />
				<Route path="/provinsi/index/:fieldName/:fieldValue" element={<ProvinsiList />} />
				<Route path="/provinsi/view/:pageid" element={<ProvinsiView />} />
				<Route path="/provinsi/add" element={<ProvinsiAdd />} />
				<Route path="/provinsi/edit/:pageid" element={<ProvinsiEdit />} />

				{/* rolepermissions pages routes */}
				<Route path="/rolepermissions" element={<RolepermissionsList />} />
				<Route path="/rolepermissions/:fieldName/:fieldValue" element={<RolepermissionsList />} />
				<Route path="/rolepermissions/index/:fieldName/:fieldValue" element={<RolepermissionsList />} />
				<Route path="/rolepermissions/view/:pageid" element={<RolepermissionsView />} />
				<Route path="/rolepermissions/add" element={<RolepermissionsAdd />} />
				<Route path="/rolepermissions/edit/:pageid" element={<RolepermissionsEdit />} />

				{/* roles pages routes */}
				<Route path="/roles" element={<RolesList />} />
				<Route path="/roles/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/index/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/view/:pageid" element={<RolesView />} />
				<Route path="/roles/add" element={<RolesAdd />} />
				<Route path="/roles/edit/:pageid" element={<RolesEdit />} />

				{/* siswa pages routes */}
				<Route path="/siswa" element={<SiswaList />} />
				<Route path="/siswa/:fieldName/:fieldValue" element={<SiswaList />} />
				<Route path="/siswa/index/:fieldName/:fieldValue" element={<SiswaList />} />
				<Route path="/siswa/view/:pageid" element={<SiswaView />} />
				<Route path="/siswa/add" element={<SiswaAdd />} />
				<Route path="/siswa/edit/:pageid" element={<SiswaEdit />} />

				{/* universitas pages routes */}
				<Route path="/universitas" element={<UniversitasList />} />
				<Route path="/universitas/:fieldName/:fieldValue" element={<UniversitasList />} />
				<Route path="/universitas/index/:fieldName/:fieldValue" element={<UniversitasList />} />
				<Route path="/universitas/view/:pageid" element={<UniversitasView />} />
				<Route path="/universitas/add" element={<UniversitasAdd />} />
				<Route path="/universitas/edit/:pageid" element={<UniversitasEdit />} />

				{/* users pages routes */}
				<Route path="/users" element={<UsersList />} />
				<Route path="/users/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/index/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/view/:pageid" element={<UsersView />} />
				<Route path="/users/add" element={<UsersAdd />} />
				<Route path="/users/edit/:pageid" element={<UsersEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
