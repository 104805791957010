import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const SiswaAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		nama_siswa: yup.string().nullable().label("Nama Siswa"),
		nik: yup.string().nullable().label("Nik"),
		jenis_kelamin: yup.string().nullable().label("Jenis Kelamin"),
		tanggal_lahir: yup.string().nullable().label("Tanggal Lahir"),
		email: yup.string().email().nullable().label("Email"),
		no_telp: yup.string().nullable().label("No Telp"),
		asal_daerah: yup.string().nullable().label("Asal Daerah"),
		provinsi: yup.string().nullable().label("Provinsi"),
		alamat: yup.string().nullable().label("Alamat"),
		status_siswa: yup.string().nullable().label("Status Siswa"),
		status_dokumen: yup.string().nullable().label("Status Dokumen"),
		status_keuangan: yup.string().nullable().label("Status Keuangan"),
		transcript_notes: yup.string().nullable().label("Transcript Notes"),
		rekomendasi: yup.string().nullable().label("Rekomendasi"),
		arsipdokumen_link: yup.string().nullable().label("Arsipdokumen Link")
	});
	
	//form default values
	const formDefaultValues = {
		nama_siswa: "NULL", 
		nik: "NULL", 
		jenis_kelamin: "NULL", 
		tanggal_lahir: "NULL", 
		email: "NULL", 
		no_telp: "NULL", 
		asal_daerah: "NULL", 
		provinsi: "NULL", 
		alamat: '', 
		status_siswa: "NULL", 
		status_dokumen: "NULL", 
		status_keuangan: "NULL", 
		transcript_notes: '', 
		rekomendasi: '', 
		arsipdokumen_link: "NULL", 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/siswa`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="SiswaAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Add New Siswa"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nama Siswa 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nama_siswa"  onChange={formik.handleChange}  value={formik.values.nama_siswa}   label="Nama Siswa" type="text" placeholder="Enter Nama Siswa"        className={inputClassName(formik?.errors?.nama_siswa)} />
                                                <ErrorMessage name="nama_siswa" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nik 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nik"  onChange={formik.handleChange}  value={formik.values.nik}   label="Nik" type="text" placeholder="Enter Nik"        className={inputClassName(formik?.errors?.nik)} />
                                                <ErrorMessage name="nik" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Jenis Kelamin 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="jenis_kelamin"  onChange={formik.handleChange}  value={formik.values.jenis_kelamin}   label="Jenis Kelamin" type="text" placeholder="Enter Jenis Kelamin"        className={inputClassName(formik?.errors?.jenis_kelamin)} />
                                                <ErrorMessage name="jenis_kelamin" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tanggal Lahir 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="tanggal_lahir" showButtonBar className={inputClassName(formik?.errors?.tanggal_lahir)} dateFormat="yy-mm-dd" value={formik.values.tanggal_lahir} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="tanggal_lahir" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Email 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="email"  onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="Enter Email"        className={inputClassName(formik?.errors?.email)} />
                                                <ErrorMessage name="email" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                No Telp 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="no_telp"  onChange={formik.handleChange}  value={formik.values.no_telp}   label="No Telp" type="text" placeholder="Enter No Telp"        className={inputClassName(formik?.errors?.no_telp)} />
                                                <ErrorMessage name="no_telp" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Asal Daerah 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="asal_daerah"  onChange={formik.handleChange}  value={formik.values.asal_daerah}   label="Asal Daerah" type="text" placeholder="Enter Asal Daerah"        className={inputClassName(formik?.errors?.asal_daerah)} />
                                                <ErrorMessage name="asal_daerah" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Provinsi 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="provinsi"  onChange={formik.handleChange}  value={formik.values.provinsi}   label="Provinsi" type="text" placeholder="Enter Provinsi"        className={inputClassName(formik?.errors?.provinsi)} />
                                                <ErrorMessage name="provinsi" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Alamat 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="alamat"  className={inputClassName(formik?.errors?.alamat)}   value={formik.values.alamat} placeholder="Enter Alamat" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="alamat" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Status Siswa 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="status_siswa"  onChange={formik.handleChange}  value={formik.values.status_siswa}   label="Status Siswa" type="text" placeholder="Enter Status Siswa"        className={inputClassName(formik?.errors?.status_siswa)} />
                                                <ErrorMessage name="status_siswa" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Status Dokumen 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="status_dokumen"  onChange={formik.handleChange}  value={formik.values.status_dokumen}   label="Status Dokumen" type="text" placeholder="Enter Status Dokumen"        className={inputClassName(formik?.errors?.status_dokumen)} />
                                                <ErrorMessage name="status_dokumen" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Status Keuangan 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="status_keuangan"  onChange={formik.handleChange}  value={formik.values.status_keuangan}   label="Status Keuangan" type="text" placeholder="Enter Status Keuangan"        className={inputClassName(formik?.errors?.status_keuangan)} />
                                                <ErrorMessage name="status_keuangan" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Transcript Notes 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="transcript_notes"  className={inputClassName(formik?.errors?.transcript_notes)}   value={formik.values.transcript_notes} placeholder="Enter Transcript Notes" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="transcript_notes" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Rekomendasi 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="rekomendasi"  className={inputClassName(formik?.errors?.rekomendasi)}   value={formik.values.rekomendasi} placeholder="Enter Rekomendasi" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="rekomendasi" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Arsipdokumen Link 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="arsipdokumen_link"  onChange={formik.handleChange}  value={formik.values.arsipdokumen_link}   label="Arsipdokumen Link" type="text" placeholder="Enter Arsipdokumen Link"        className={inputClassName(formik?.errors?.arsipdokumen_link)} />
                                                <ErrorMessage name="arsipdokumen_link" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Submit" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
SiswaAddPage.defaultProps = {
	primaryKey: 'siswa_id',
	pageName: 'siswa',
	apiPath: 'siswa/add',
	routeName: 'siswaadd',
	submitButtonLabel: "Submit",
	formValidationError: "Form is invalid",
	formValidationMsg: "Please complete the form",
	msgTitle: "Create Record",
	msgAfterSave: "Record added successfully",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default SiswaAddPage;
