import { useEffect } from 'react';
import { CanView } from 'components/Can';
import { TabView, TabPanel } from 'primereact/tabview';
import { Title } from 'components/Title';
import AkademikinfoListPage from 'pages/akademikinfo/List';
import DokumeninfoListPage from 'pages/dokumeninfo/List';
import InvoicesListPage from 'pages/invoices/List';
import useApp from 'hooks/useApp';

const MasterDetailPages = (props) => {
		const app = useApp();
	const { masterRecord, scrollIntoView = true } = props;
	const activeTab = 0;
	function scrollToDetailPage() {
		if (scrollIntoView) {
			const pageElement = document.getElementById('master-detailpage');
			if(pageElement){
				pageElement.scrollIntoView({behavior:'smooth', block:'start'});
			}
		}
	}
	// pass form data from master to detail
	function setDetailPageFormData(){
		const record = masterRecord;
		// set  form data
		const akademikinfoFormData = { siswa_id:record?.siswa_id }
		app.setPageFormData('akademikinfo', akademikinfoFormData);
		// set  form data
		const dokumeninfoFormData = {  }
		app.setPageFormData('dokumeninfo', dokumeninfoFormData);
		// set  form data
		const invoicesFormData = {  }
		app.setPageFormData('invoices', invoicesFormData);
	}
	// pass form data from master to detail
	useEffect(() => {
		scrollToDetailPage();
		setDetailPageFormData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [masterRecord]);
	if(masterRecord){
		return (
<div id="master-detailpage">
    <TabView value={activeTab}>
        <TabPanel header={<Title title="Siswa Akademikinfo"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
            <CanView pagePath="akademikinfo">
                <div className="reset-grid">
                    <AkademikinfoListPage isSubPage  fieldName="akademikinfo.siswa_id" fieldValue={masterRecord.siswa_id} showBreadcrumbs={false} showHeader={false} showFooter={true}>
                        </AkademikinfoListPage>
                    </div>
                </CanView>
            </TabPanel>
            <TabPanel header={<Title title="Siswa Dokumeninfo"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
                <CanView pagePath="dokumeninfo">
                    <div className="reset-grid">
                        <DokumeninfoListPage isSubPage  fieldName="dokumeninfo.siswa_id" fieldValue={masterRecord.siswa_id} showBreadcrumbs={false} showHeader={false} showFooter={true}>
                        </DokumeninfoListPage>
                    </div>
                </CanView>
            </TabPanel>
            <TabPanel header={<Title title="Siswa Invoices"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
                <CanView pagePath="invoices">
                    <div className="reset-grid">
                        <InvoicesListPage isSubPage  fieldName="invoices.siswa_id" fieldValue={masterRecord.siswa_id} showBreadcrumbs={false} showHeader={false} showFooter={true}>
                        </InvoicesListPage>
                    </div>
                </CanView>
            </TabPanel>
        </TabView>
    </div>
		);
	}
}
export default MasterDetailPages;
