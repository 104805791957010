import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const AkademikinfoAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		siswa_id: yup.string().nullable().label("Siswa Id"),
		universitas_id: yup.string().nullable().label("Universitas Id"),
		jenjang_pendidikan: yup.string().nullable().label("Jenjang Pendidikan"),
		semester: yup.number().nullable().label("Semester"),
		gpa: yup.number().nullable().label("Gpa"),
		jurusan: yup.string().nullable().label("Jurusan"),
		tahun_akademik: yup.number().nullable().label("Tahun Akademik")
	});
	
	//form default values
	const formDefaultValues = {
		siswa_id: '', 
		universitas_id: '', 
		jenjang_pendidikan: '', 
		semester: '', 
		gpa: '', 
		jurusan: '', 
		tahun_akademik: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/akademikinfo`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="AkademikinfoAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Add New Akademikinfo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Siswa Id 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/siswa_id_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="siswa_id"     optionLabel="label" optionValue="value" value={formik.values.siswa_id} onChange={formik.handleChange} options={response} label="Siswa Id"  placeholder="Select a value ..." filter={true} className={inputClassName(formik?.errors?.siswa_id)}   />
                                                    <ErrorMessage name="siswa_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Universitas Id 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/universitas_id_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="universitas_id"     optionLabel="label" optionValue="value" value={formik.values.universitas_id} onChange={formik.handleChange} options={response} label="Universitas Id"  placeholder="Select a value ..."  className={inputClassName(formik?.errors?.universitas_id)}   />
                                                    <ErrorMessage name="universitas_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Jenjang Pendidikan 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="jenjang_pendidikan"  onChange={formik.handleChange}  value={formik.values.jenjang_pendidikan}   label="Jenjang Pendidikan" type="text" placeholder="Enter Jenjang Pendidikan"        className={inputClassName(formik?.errors?.jenjang_pendidikan)} />
                                                <ErrorMessage name="jenjang_pendidikan" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Semester 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="semester"  onChange={formik.handleChange}  value={formik.values.semester}   label="Semester" type="number" placeholder="Enter Semester"  min={0}  step="any"    className={inputClassName(formik?.errors?.semester)} />
                                                <ErrorMessage name="semester" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Gpa 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="gpa"  onChange={formik.handleChange}  value={formik.values.gpa}   label="Gpa" type="number" placeholder="Enter Gpa"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.gpa)} />
                                                <ErrorMessage name="gpa" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Jurusan 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="jurusan"  onChange={formik.handleChange}  value={formik.values.jurusan}   label="Jurusan" type="text" placeholder="Enter Jurusan"        className={inputClassName(formik?.errors?.jurusan)} />
                                                <ErrorMessage name="jurusan" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tahun Akademik 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="tahun_akademik"  onChange={formik.handleChange}  value={formik.values.tahun_akademik}   label="Tahun Akademik" type="number" placeholder="Enter Tahun Akademik"  min={0}  step="any"    className={inputClassName(formik?.errors?.tahun_akademik)} />
                                                <ErrorMessage name="tahun_akademik" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Submit" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
AkademikinfoAddPage.defaultProps = {
	primaryKey: 'record_id',
	pageName: 'akademikinfo',
	apiPath: 'akademikinfo/add',
	routeName: 'akademikinfoadd',
	submitButtonLabel: "Submit",
	formValidationError: "Form is invalid",
	formValidationMsg: "Please complete the form",
	msgTitle: "Create Record",
	msgAfterSave: "Record added successfully",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default AkademikinfoAddPage;
